import React, { useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import moduleStyles from "./NewAccountModal.module.scss";
import {
  Button,
  Icons,
  ToastColor,
  TextController,
  DateSelectController,
  EmailController,
  Grid,
  Text,
  IconButton,
} from "@pushpress/shared-components";
import { isMinorParticipant, multiStyles } from "../../utils";
import {
  postCreateLinkedAccount,
  PostCreateLinkedAccountVariables,
} from "../../api";
import { useAppMutation } from "../../utils/hooks";
import { useAppContext } from "../../contexts/AppContext";
import { useToast } from "../ToastProvider";
import { useAnalytics } from "../AnalyticsProvider";

interface AccountForm {
  firstName: string;
  lastName: string;
  dob: string;
  email: string;
  clientUuid: string;
  guardianName: string;
  guardianEmail: string;
}

const S = multiStyles(moduleStyles);

interface LoginModalProps {
  closeModal: (newLinkedAccount?: LinkedAccount) => void;
}

const NewAccountModal = ({ closeModal }: LoginModalProps) => {
  const { t } = useTranslation("common");
  const { setToast } = useToast();
  const appContext = useAppContext();
  const analytics = useAnalytics();

  const { control, handleSubmit } = useForm<AccountForm>({
    mode: "onTouched",
    defaultValues: {
      guardianName: appContext.userFullName,
      guardianEmail: appContext.user?.email,
    },
  });

  const dob = useWatch({ control, name: "dob" });

  const { mutateAsync: createLinkedAccount, isLoading: createLoading } =
    useAppMutation(postCreateLinkedAccount);

  const isMinor = useMemo(() => isMinorParticipant(new Date(dob)), [dob]);

  const trackCreateLinkedAccount = () => {
    analytics.trackEvent("linked_account_created", {}, true);
  };

  const createAccount = async (values: AccountForm) => {
    const clientUuid = appContext.client.uuid;
    const userUuid = appContext.user?.userUuid!;
    const variables = convertToVariables(clientUuid, userUuid, isMinor, values);
    createLinkedAccount(variables)
      .then((linkedAccount) => {
        setToast({
          status: ToastColor.Success,
          message: t("newAccount.accountCreated"),
        });
        closeModal(linkedAccount);
        trackCreateLinkedAccount();
      })
      .catch(() => null);
  };

  return (
    <div className={S("container")}>
      <form className={S("form")} onSubmit={handleSubmit(createAccount)}>
        <div className={S("header")}>
          <Grid container>
            <Grid item xs={10}>
              <Text variant="heading-4" color="wolf-800">
                {t("newAccount.title")}
              </Text>
            </Grid>
            <Grid
              item
              xs={2}
              container
              justifyContent="flex-end"
              alignItems="center"
            >
              <IconButton icon={Icons.X} onClick={() => closeModal()} />
            </Grid>
          </Grid>
        </div>
        <div className={S("content")}>
          <Text variant="heading-5" color="wolf-600" className="mb-2">
            {t("newAccount.participantTitle")}
          </Text>
          <TextController
            label={t("newAccount.firstName")}
            placeholder={t("newAccount.firstNamePlaceholder")}
            control={control}
            name="firstName"
            required
          />
          <TextController
            label={t("newAccount.lastName")}
            placeholder={t("newAccount.lastNamePlaceholder")}
            control={control}
            name="lastName"
            required
          />
          <DateSelectController
            label={t("newAccount.dateOfBirth")}
            control={control}
            name="dob"
            required
            locale={appContext.clientLocale}
          />
          {dob && isMinor && (
            <>
              <Text variant="heading-5" color="wolf-600" className="mb-2">
                {t("newAccount.parentTitle")}
              </Text>
              <TextController
                label={t("newAccount.parentName")}
                placeholder={t("newAccount.parentNamePlaceholder")}
                control={control}
                name="guardianName"
                required
              />
              <EmailController
                label={t("newAccount.parentEmail")}
                placeholder={t("newAccount.parentEmailPlaceholder")}
                control={control}
                name="guardianEmail"
                required
              />
            </>
          )}
        </div>
        <div className={S("footer")}>
          <Button
            fullWidth
            buttonType="primary"
            text={t("newAccount.save")}
            size="large"
            loading={createLoading}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

const convertToVariables = (
  clientUuid: string,
  userUuid: string,
  isMinor: boolean,
  values: AccountForm,
): PostCreateLinkedAccountVariables => {
  const dob = new Date(values.dob).getTime();
  const accountData: NewAccount = {
    clientUuid,
    firstName: values.firstName,
    lastName: values.lastName,
    dob,
  };
  if (isMinor) {
    accountData.guardianName = values.guardianName;
    accountData.guardianEmail = values.guardianEmail;
  }
  return {
    accountData,
    primaryAccountUserUuid: userUuid,
    shareEmail: true,
  };
};

export default NewAccountModal;
