import React from "react";
import { useTranslation } from "react-i18next";
import { StripePaymentElementOptions } from "@stripe/stripe-js";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { postSavePaymentMethod } from "../../api";
import { useAppMutation, useToggle } from "../../utils/hooks";
import {
  PanelHeader,
  Button,
  Icons,
  Loader,
  ToastColor,
} from "@pushpress/shared-components";
import { extractError, multiStyles } from "../../utils";
import { useAppContext } from "../../contexts/AppContext";
import { useToast } from "../ToastProvider";
import { useAnalytics } from "../AnalyticsProvider";
import moduleStyles from "./AddPaymentModal.module.scss";

const S = multiStyles(moduleStyles);

interface AddPaymentModalProps {
  stripeCustomerId: string;
  closeModal: VoidFunction;
  selectNewPaymentMethod: (p: PaymentMethod | null) => void;
}

const AddPaymentModal: React.FC<AddPaymentModalProps> = (props) => {
  const { stripeCustomerId, selectNewPaymentMethod, closeModal } = props;

  const { t } = useTranslation("common");
  const { setToast } = useToast();
  const { client, user } = useAppContext();
  const stripe = useStripe();
  const elements = useElements();
  const analytics = useAnalytics();

  const [elementLoading, loadingElementActions] = useToggle(true);
  const [cardSaving, savingCardActions] = useToggle(false);

  const { mutateAsync: savePaymentMethod } = useAppMutation(
    postSavePaymentMethod,
  );

  const trackAddPaymentEvent = (data: { payment_type: string }) => {
    analytics.trackEvent("payment_type_added", data, true);
  };

  const saveCard = async () => {
    try {
      savingCardActions.on();
      if (!elements || !stripe) {
        throw new Error(t("paymentModal.stripeNotFound"));
      }
      const { setupIntent, error } = await stripe.confirmSetup({
        elements: elements,
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: "if_required",
      });
      if (error) {
        throw new Error(extractError(error));
      }
      if (!setupIntent || !user?.userUuid) {
        throw new Error(t("paymentModal.savePaymentFailed"));
      }
      const stripePaymentMethodId = setupIntent?.payment_method! as string;
      const paymentMethod = await savePaymentMethod({
        userUuid: user.userUuid,
        clientUuid: client.uuid,
        paymentMethodId: stripePaymentMethodId,
        customerId: stripeCustomerId,
      });
      if (!paymentMethod) {
        throw new Error(t("paymentModal.savePaymentNull"));
      }
      selectNewPaymentMethod(paymentMethod);
      closeModal();
      trackAddPaymentEvent({
        payment_type: paymentMethod.type,
      });
    } catch (e: any) {
      setToast({
        status: ToastColor.Error,
        message: extractError(e),
      });
    } finally {
      savingCardActions.off();
    }
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    wallets: {
      applePay: "never",
      googlePay: "never",
    },
  };

  return (
    <div className={S("container")}>
      <PanelHeader title="New Account">
        <PanelHeader.ActionMenu>
          <Button
            buttonType="secondary"
            text="Cancel"
            size="medium"
            onClick={closeModal}
          />
          <Button
            buttonType="primary"
            text="Save"
            icon={Icons.Check}
            iconLocation="left"
            size="medium"
            loading={cardSaving}
            disabled={!stripe}
            onClick={saveCard}
          />
        </PanelHeader.ActionMenu>
      </PanelHeader>
      <div className={S("content")}>
        {elementLoading && <Loader />}
        <PaymentElement
          options={paymentElementOptions}
          onReady={loadingElementActions.off}
        />
      </div>
    </div>
  );
};

export default AddPaymentModal;
