import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Icons, Toast, ToastColor } from "@pushpress/shared-components";
import { postResetPassword } from "../../../api";
import { useAppContext } from "../../../contexts/AppContext";
import { useAppMutation } from "../../../utils/hooks";
import PageContainer from "../../../components/PageContainer";
import ResetPasswordForm from "../../../components/ResetPasswordForm";
import ResetPasswordSummary from "../../../components/ResetPasswordSummary";
import { useTranslation } from "react-i18next";
import { useToast } from "../../../components/ToastProvider";
import { parseBase64 } from "../../../utils";

interface ResetParams {
  email?: string;
  token?: string;
}

const ResetPassword = () => {
  const [searchParams] = useSearchParams();

  const { setToast } = useToast();

  const { t } = useTranslation("common");

  const { gotoLogin } = useAppContext();

  const { email, token } = useMemo<ResetParams>(() => {
    const params = searchParams.get("reset");
    if (params) {
      return parseBase64(params);
    }
    return {};
  }, [searchParams]);

  const { mutateAsync: resetPassword, isLoading } =
    useAppMutation(postResetPassword);

  const handleSubmit = async (password: string) => {
    try {
      await resetPassword({
        password,
        token: token!,
      });
      setToast({
        status: ToastColor.Success,
        message: t("resetPassword.resetSuccess"),
      });
      gotoLogin(email!);
    } catch {}
  };

  const isParamsValid = !!email && !!token;

  return (
    <PageContainer>
      <PageContainer.LeftPane>
        <ResetPasswordSummary email={email!} />
      </PageContainer.LeftPane>
      <PageContainer.RightPane>
        {isParamsValid ? (
          <ResetPasswordForm onSubmit={handleSubmit} loading={isLoading} />
        ) : (
          <Toast
            message={t("resetPassword.invalidParams")}
            icon={Icons.Alert}
            iconPosition="center"
          />
        )}
      </PageContainer.RightPane>
    </PageContainer>
  );
};

export default ResetPassword;
