import React, { HtmlHTMLAttributes, ReactNode } from "react";
import moduleStyles from "./IconCard.module.scss";
import { joinClasses, multiStyles } from "../../utils";
import { Text, IconsProps } from "@pushpress/shared-components";

const S = multiStyles(moduleStyles);

type IconProps = IconsProps;

export interface IconCardProps extends HtmlHTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  icon: (props: IconProps) => JSX.Element;
}

const IconCard: React.FC<IconCardProps> = (props) => {
  const { children, icon: Icon, className, ...other } = props;

  return (
    <div {...other} className={joinClasses(S("root"), className)}>
      <div className={S("icon")}>
        <Icon />
      </div>
      <div className={S("content")}>
        <Text variant="body-md" whiteSpace="pre-line">
          <>{children}</>
        </Text>
      </div>
    </div>
  );
};

export default IconCard;
