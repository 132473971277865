import React from "react";
import { useTranslation } from "react-i18next";
import { postSavePaymentMethod } from "../../api";
import { useAppMutation, useToggle } from "../../utils/hooks";
import {
  PanelHeader,
  Button,
  Icons,
  ToastColor,
  TextController,
  EmailController,
} from "@pushpress/shared-components";
import { extractError, multiStyles } from "../../utils";
import { useAppContext } from "../../contexts/AppContext";
import { useToast } from "../ToastProvider";
import moduleStyles from "./CadPaymentModal.module.scss";
import { loadStripe } from "@stripe/stripe-js";
import { useForm } from "react-hook-form";

const S = multiStyles(moduleStyles);

interface CADForm {
  name: string;
  email: string;
}

interface AddPaymentModalProps {
  stripe: ReturnType<typeof loadStripe>;
  stripeSecret: string;
  stripeCustomerId: string;
  closeModal: VoidFunction;
  selectNewPaymentMethod: (p: PaymentMethod | null) => void;
}

const AddPaymentModal: React.FC<AddPaymentModalProps> = (props) => {
  const {
    stripe,
    stripeSecret,
    stripeCustomerId,
    selectNewPaymentMethod,
    closeModal,
  } = props;
  const { client, user, userFullName } = useAppContext();
  const { control, handleSubmit, getValues, formState } = useForm<CADForm>({
    mode: "onTouched",
    defaultValues: {
      name: userFullName,
      email: user?.email,
    },
  });
  const { t } = useTranslation("common");
  const { setToast } = useToast();
  const [cardSaving, savingCardActions] = useToggle(false);
  const { mutateAsync: savePaymentMethod } = useAppMutation(
    postSavePaymentMethod,
  );

  const saveCard = async (values: CADForm) => {
    try {
      savingCardActions.on();
      const stripeMethods = await stripe;
      if (!stripeMethods) {
        throw new Error(t("paymentModal.stripeNotFound"));
      }
      const { setupIntent, error } = await stripeMethods.confirmAcssDebitSetup(
        stripeSecret,
        {
          payment_method: {
            billing_details: values,
          },
        },
      );
      if (error) {
        throw new Error(extractError(error));
      }
      if (!setupIntent || !user?.userUuid) {
        throw new Error(t("paymentModal.savePaymentFailed"));
      }
      const stripePaymentMethodId = setupIntent?.payment_method! as string;
      const paymentMethod = await savePaymentMethod({
        userUuid: user.userUuid,
        clientUuid: client.uuid,
        paymentMethodId: stripePaymentMethodId,
        customerId: stripeCustomerId,
      });
      if (!paymentMethod) {
        throw new Error(t("paymentModal.savePaymentNull"));
      }
      selectNewPaymentMethod(paymentMethod);
      closeModal();
    } catch (e: any) {
      setToast({
        status: ToastColor.Error,
        message: extractError(e),
      });
    } finally {
      savingCardActions.off();
    }
  };

  return (
    <div className={S("container")}>
      <PanelHeader title="New Account">
        <PanelHeader.ActionMenu>
          <Button
            buttonType="secondary"
            text="Cancel"
            size="medium"
            onClick={closeModal}
          />
          <Button
            buttonType="primary"
            text="Save"
            icon={Icons.Check}
            iconLocation="left"
            size="medium"
            loading={cardSaving}
            disabled={!formState.isValid}
            onClick={() => saveCard(getValues())}
          />
        </PanelHeader.ActionMenu>
      </PanelHeader>
      <form
        noValidate
        onSubmit={handleSubmit(saveCard)}
        className={S("content")}
      >
        <TextController
          label={t("paymentModal.yourName")}
          control={control}
          name="name"
          required
        />
        <EmailController
          label={t("paymentModal.yourEmail")}
          control={control}
          name="email"
          required
        />
      </form>
    </div>
  );
};

export default AddPaymentModal;
